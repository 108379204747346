import React, { useContext } from "react";
import { Typography, makeStyles, Box, Container } from "@material-ui/core";
import SettingsContext from "src/context/SettingsContext";
import PropTypes from "prop-types";
import Header from "./Header";
// import TopBar from "../DashboardLayout/TopBar";
const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    position: "relative",
    height: "100vh",
    backgroundColor: "#fff",
  },
  root1: {
    backgroundColor: "#24232B",
    overflow: "hidden",
    position: "relative",
    height: "100vh",
  },
  loginLayoutBox: {
    display: "flex",
    position: "relative",
    justifyContent: "start",
    alignItems: "end",
    height: "100vh",
    // backgroundImage: "url(./images/banner_login.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
    backgroundSize: "contain",
    objectFit: "cover !important",
    background: "#080d16",
    "&::after": {
      position: "absolute",
      backgroundImage:
        "linear-gradient(88deg, rgb(7 13 21 / 57%) 27.27%, #080d16 100.28%)",
      content: "'' ",
      height: "100%",
      width: "100%",
      top: "0",
      left: "0",
      zIndex: "1",
    },
  },
  loginContentLayoutBox: {
    position: "absolute",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    height: "100vh",
    zIndex: "9",
    right: 0,
    width: "45%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "80px 0 50px",
    },
  },
  contentBox: {
    left: "40px",
    bottom: "29px !important",
    zIndex: "9",
    position: "absolute !important",
    "& h1": {
      marginBottom: "8px",
      maxWidth: "500px",
      lineHeight: "51px",
    },
    "& h6": {
      color: "rgba(255, 255, 255, 0.60)",
      fontSize: "14px",
      maxWidth: "579px",
      fontWeight: "300",
    },
  },
}));

export default function LoginLayout({ children }) {
  const classes = useStyles();
  const themeSeeting = useContext(SettingsContext);
  return (
    <div
      className={
        themeSeeting.settings.theme === "DARK"
          ? `${classes.root1}`
          : `${classes.root}`
      }
    >
      <Header />
      <Box
        className={classes.loginLayoutBox}
        // style={
        //   themeSeeting.settings.theme === "DARK"
        //     ? { backgroundImage: "url(./images/banner_login.svg)" }
        //     : { backgroundImage: "url(./images/backgroundLight.png)" }
        // }
      >
        <img
          src="images/banner_login_1.svg"
          style={{ width: "auto", height: "100%" }}
        />
        <Box className={classes.contentBox}>
          <Container maxWidth="false">
            <Typography variant="h1" color="primary" style={{ color: "#fff" }}>
              Don’t Worry please reset your password
            </Typography>

            <Typography variant="h6">
              Don't worry, we've got you covered! If you've forgotten your
              password, simply enter the email address associated with your
              account, and we'll guide you through the process of resetting it.
              Check your inbox for instructions on how to proceed. If you need
              any further assistance, don't hesitate to reach out to our support
              team. We're here to help you regain access to your account
              smoothly and securely.
            </Typography>
          </Container>
        </Box>

        <Box
          className={classes.loginContentLayoutBox}
          style={
            themeSeeting.settings.theme === "DARK"
              ? {
                  background: "#272932",
                }
              : { backgroundColor: "white" }
          }
        >
          <Container maxWidth="sm">
            <Box style={{ width: "100%" }}>{children}</Box>
          </Container>
        </Box>
      </Box>
    </div>
  );
}
LoginLayout.propTypes = {
  children: PropTypes.node,
};
