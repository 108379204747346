import React, { useContext } from "react";
import { Typography, makeStyles, Box, Container } from "@material-ui/core";
import SettingsContext from "src/context/SettingsContext";
import PropTypes from "prop-types";
import Header from "./Header";
// import TopBar from "../DashboardLayout/TopBar";
const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    position: "relative",
    height: "100vh",
    backgroundColor: "#fff",
  },
  root1: {
    backgroundColor: "#24232B",
    overflow: "hidden",
    position: "relative",
    height: "100vh",
  },
  loginLayoutBox: {
    display: "flex",
    position: "relative",
    justifyContent: "start",
    alignItems: "end",
    height: "100vh",
    // backgroundImage: "url(./images/banner_login.png)",
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "left",
    // backgroundSize: "contain",
    // objectFit: "cover !important",
    // background: "#080d16",
    "&::after": {
      position: "absolute",
      backgroundImage:
        "linear-gradient(88deg, rgb(7 13 21 / 1%) 27.27%, #24232b 100.28%)",
      content: "'' ",
      height: "100%",
      width: "100%",
      top: "0",
      right: "45%",
      zIndex: "1",
    },
    "& .leftBox": {
      position: "absolute",
      width: "55%",
      left: "0",
      background: "#24232B",
      "& .loginlogo": {
        position: "absolute",
        right: "7%",
        top: "38%",
        zIndex: "999",
        width: "auto",
        maxWidth: "250px",
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
    },
  },
  loginContentLayoutBox: {
    position: "absolute",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    height: "100vh",
    zIndex: "9",
    right: 0,
    width: "45%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "80px 0 50px",
    },
  },
  contentBox: {
    position: "absolute !important",
    zIndex: "9",
    bottom: "10px !important",
    left: "40px",
    "& h1": {
      color: "#fff",
      marginBottom: "8px",
    },
    "& h6": {
      color: "rgba(255, 255, 255, 0.60)",
      fontSize: "14px",
      maxWidth: "456px",
      fontWeight: "300",
    },
  },
}));

export default function LoginLayout({ children }) {
  const classes = useStyles();
  const themeSeeting = useContext(SettingsContext);
  return (
    <div
      className={
        themeSeeting.settings.theme === "DARK"
          ? `${classes.root1}`
          : `${classes.root}`
      }
    >
      <Header />
      <Box
        className={classes.loginLayoutBox}
        // style={
        //   themeSeeting.settings.theme === "DARK"
        //     ? { backgroundImage: "url(./images/banner_login.svg)" }
        //     : { backgroundImage: "url(./images/backgroundLight.png)" }
        // }
      >
        <Box className="leftBox">
          <img
            src="images/men.png"
            style={{
              width: "auto",
              height: "100%",
              // position: "relative",
              // zIndex: "999",
            }}
          />
          <img src="/images/login_logo.png" className="loginlogo" />
          <Box className={classes.contentBox}>
            <Container maxWidth="false">
              <Typography variant="h1" color="primary">
                Welcome Back
              </Typography>

              <Typography variant="h6">
                It's fantastic to have you here once again. Take a moment to
                sign in and access all the features and benefits awaiting you in
                your account.
              </Typography>
            </Container>
          </Box>
        </Box>

        <Box
          className={classes.loginContentLayoutBox}
          style={
            themeSeeting.settings.theme === "DARK"
              ? {
                  background: "#272932",
                }
              : { backgroundColor: "white" }
          }
        >
          <Container maxWidth="sm">
            <Box style={{ width: "100%" }}>{children}</Box>
          </Container>
        </Box>
      </Box>
    </div>
  );
}
LoginLayout.propTypes = {
  children: PropTypes.node,
};
