import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  makeStyles,
  IconButton,
  Typography,
  SvgIcon,
  Toolbar,
  AppBar,
  Hidden,
  Avatar,
  Grid,
  Box,
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "src/context/Auth";
import Logo from "src/component/Logo";
import { BiBell } from "react-icons/bi";
import SettingsContext from "src/context/SettingsContext";
import { FiSun } from "react-icons/fi";
import { FaRegMoon } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  backgroundRemover: {
    "& .MuiPaper-root": {
      backgroundColor: "transparent !important",
    },
  },
  toolbarLight: {
    background: "transparent !important",
  },

  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  mainheader: {
    width: "100%",
    padding: "30px 0 0",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 0 0",
    },
    "& svg": {
      color: theme.palette.text.primary,
    },
    "& .themeButton": {
      marginRight: "18px",
      [theme.breakpoints.down("xs")]: {
        marginRight: "0px",
      },
    },
    "& .leftBox": {
      width: "306px",
      marginLeft: "45px",
      [theme.breakpoints.down("md")]: {
        width: "200px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "150px",
        marginLeft: "15px",
      },
      "& img": {
        width: "150px",
        [theme.breakpoints.down("xs")]: {
          width: "144px",
          marginLeft: "0px",
        },
      },
    },
  },
}));

const Header = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const history = useHistory();

  const themeSeeting = useContext(SettingsContext);
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };
  const [profileData, setProfileData] = useState({});

  useEffect(() => {
    if (auth?.userData) {
      setProfileData({
        profilePic: auth?.userData?.profilePic,
      });
    } // eslint-disable-next-line
  }, [auth?.userData]);
  return (
    <AppBar
      className={`${clsx(classes.root, className)}`}
      color="inherit"
      {...rest}
      style={{ backgroundColor: "transparent", boxShadow: "none" }}
    >
      <Toolbar>
        <Box className={classes.mainheader}>
          <Box className="displaySpacebetween">
            <Box className="leftBox">
              <Link to={window.location.pathname == "/" ? "" : "/dashboard"}>
                <Logo width="125" />
              </Link>
            </Box>
            <Box className="themeButton">
              {themeSeeting.settings.theme === "DARK" ? (
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    changeTheme("LIGHT");
                  }}
                >
                  <FiSun />
                </IconButton>
              ) : (
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    changeTheme("DARK");
                  }}
                >
                  <FaRegMoon />
                </IconButton>
              )}
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};
Header.defaultProps = {
  onMobileNavOpen: () => {},
};

export default Header;

export function TopBarData() {
  const classes = useStyles();
  const auth = useContext(AuthContext);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Hidden xsDown>
          <Box>
            <Typography variant="h5">NFT Marketplace</Typography>
            <Typography variant="body1" style={{ color: "#ffffff9c" }}>
              example@gmail.com
            </Typography>
          </Box>
        </Hidden>
        &nbsp; &nbsp;
        <Avatar
          src={
            auth?.userData?.profilePic
              ? `${auth?.userData?.profilePic}`
              : "https://picsum.photos/533/357"
          }
          className={classes.avatar}
          // onClick={() => history.push("/admin-profile")}
        />
      </Box>
    </>
  );
}
