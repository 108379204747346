import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import ForgoutLayout from "src/layouts/ForgoutLayout";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/index")),
  },
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },

  {
    // exact: true,
    path: "/forget",
    layout: ForgoutLayout,
    component: lazy(() => import("src/views/pages/Auth/Forget")),
  },
  {
    // exact: true,
    path: "/reset-password",
    layout: ForgoutLayout,
    component: lazy(() => import("src/views/pages/Auth/ResetPassword")),
  },
  {
    // exact: true,
    path: "/verify-otp",
    layout: ForgoutLayout,
    component: lazy(() => import("src/views/pages/Auth/Verifyotp")),
  },

  {
    // exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Auth/ChangePassword")),
  },
  {
    // exact: true,
    // guard: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/DashdoardHome")),
  },

  {
    // exact: true,
    // guard: true,
    path: "/EmailVerificationMeth",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/2FAuthentication/emailVerify/EmailVerificationMeth"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/subscription-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubscriptionManage/Subscription")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-subscription",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubscriptionManage/AddSubscibe")
    ),
  },
  {
    // exact: true,
    // guard: true,
    path: "/exchange",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Exchange")),
  },
  {
    // exact: true,
    // guard: true,
    path: "/exchange-tab",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ExchangeSection/ExchangeTab")
    ),
  },
  {
    // exact: true,
    // guard: true,
    path: "/exchange-first",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ExchangeMain")),
  },
  {
    // exact: true,
    // guard: true,
    path: "/no-permissions",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ExchangeMain")),
  },
  {
    // exact: true,
    // guard: true,
    path: "/direct-plan",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Arbitrage/direct")),
  },

  {
    // exact: true,
    // guard: true,
    path: "/intra",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Arbitrage/Intra")),
  },
  {
    // exact: true,
    // guard: true,
    path: "/direct",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Arbitrage/DirectPlan")
    ),
  },
  // {
  //   exact: true,
  //   path: "/direct",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Dashboard/Direct")),
  // },
  // {
  //   guard: true,
  //   exact: true,
  //   path: "/triangular",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Dashboard/Triangular")),
  // },
  {
    // exact: true,
    // guard: true,
    path: "/triangular",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Arbitrage/Trangular")
    ),
  },
  {
    // guard: true,
    // exact: true,
    path: "/loop",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Loop")),
  },
  // {
  //   exact: true,
  //   guard: true,
  //   path: "/direct-plan",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Dashboard/DirectFirst")),
  // },
  // {
  //   exact: true,
  //   guard: true,
  //   path: "/intra",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Dashboard/Intra")),
  // },
  {
    // exact: true,
    // guard: true,
    path: "/transactions-history",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Transection")),
  },
  {
    exact: true,
    path: "/WhiteList-details",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/WhiteListDetails")),
  },
  {
    exact: true,
    guard: true,
    path: "/subscription",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Subscription")),
  },
  {
    exact: true,
    guard: true,
    path: "/invite",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Invite")),
  },
  {
    exact: true,
    guard: true,
    path: "/user-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/UserManagement")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-user",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/InviteView")
    ),
  },
  {
    path: "/profit-table",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ProfitDistributionCenter/ProfitTable")
    ),
  },
  {
    path: "/add-wallet",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ProfitDistributionCenter/AddWallet.js")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/invite-view",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/InviteView")),
  },
  {
    exact: true,
    guard: true,
    path: "/invite-edit",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/InviteEdit")),
  },
  {
    exact: true,
    path: "/setting",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Setting/BotSetting")
    ),
  },
  {
    exact: true,
    path: "/google-authantication",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/2FAuthentication/googleVerify/GoogleVerifyTab"
      )
    ),
  },

  {
    exact: true,
    path: "/google-verification",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/2FAuthentication/googleVerify/GoogleAuthentication"
      )
    ),
  },

  {
    exact: true,
    path: "/google-success",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/2FAuthentication/googleVerify/Verified")
    ),
  },
  {
    exact: true,
    path: "/email-authantication",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/2FAuthentication/emailVerify/EmailVerifyTab"
      )
    ),
  },
  {
    // exact: true,
    path: "/auto-trade",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/autoTrade/MainIndex")
    ),
  },

  // {
  //   exact: true,
  //   guard: true,
  //   path: "/setting",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Dashboard/Setting")),
  // },
  {
    // exact: true,
    // guard: true,
    path: "/my-wallet",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Wallet/Mywallet")),
  },
  {
    // exact: true,
    // guard: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Profile")),
  },
  {
    // exact: true,
    // guard: true,
    path: "/edit-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/EditProfile")),
  },
  {
    // exact: true,
    // guard: true,
    path: "/notification",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static/Notification")),
  },
  {
    exact: true,
    path: "/static-content-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/ContentManagement/StaticContentManagement"
      )
    ),
  },
  {
    exact: true,
    path: "/edit-content",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ContentManagement/EditContent")
    ),
  },
  {
    exact: true,
    path: "/view-content",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ContentManagement/ViewContent")
    ),
  },
  {
    exact: true,
    path: "/view-subscription",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubscriptionManage/ViewSubscription")
    ),
  },
  {
    exact: true,
    layout: HomeLayout,
    path: "/privacy-policy",
    component: lazy(() =>
      import("src/views/pages/Dashboard/ContentManagement/Privacy")
    ),
  },
  {
    exact: true,
    layout: HomeLayout,
    path: "/FAQ",
    component: lazy(() =>
      import("src/views/pages/Dashboard/ContentManagement/FaqSection")
    ),
  },
  {
    exact: true,

    path: "/term-and-condition",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ContentManagement/Terms")
    ),
  },
  {
    exact: true,
    path: "/faqs",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FaqManagement/Faqs")
    ),
  },
  {
    exact: true,
    path: "/view-faq",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FaqManagement/ViewFaq")
    ),
  },
  {
    exact: true,
    path: "/add-faq",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FaqManagement/AddFaq")
    ),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
