import React, { useEffect, useState, useContext } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FaBarcode, FaSquarespace } from "react-icons/fa";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import { MdOutlineNavigateNext } from "react-icons/md";
import SettingsContext from "src/context/SettingsContext";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  Button,
  ListSubheader,
  makeStyles,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@material-ui/core";

import NavItem from "./NavItem";
import { MdHistory, MdOutlineHelpCenter } from "react-icons/md";
import { RiSettingsLine, RiWallet3Line } from "react-icons/ri";
import { AuthContext } from "src/context/Auth";
import { AiFillDashboard } from "react-icons/ai";
import { BsFillMenuAppFill, BsFillPersonPlusFill } from "react-icons/bs";
import Logo from "./Logo";

const sections = [
  {
    items: [
      {
        title: "Dashboard",
        modules: "dashboard",
        icon: "/images/Dashboard/dashboardicon.svg",
        href: "/dashboard",
        isSub: false,
      },
      {
        title: "Exchanges",
        modules: "exchange",
        icon: "/images/Dashboard/exchange.svg",
        // img: <img src="images/dash1.png"/>,
        href: "/exchange-first",
        isSub: false,
      },

      // {
      //   title: "Whitelist Details",
      //   modules: "",
      //   icon: FaSquarespace,
      //   // img: <img src="images/dash1.png"/>,
      //   href: "/WhiteList-details",
      // },

      // {
      //   title: "Subscription Management",
      //   modules: "subscription",
      //   icon: SubscriptionsIcon,
      //   // img: <img src="images/dash1.png"/>,
      //   href: "/subscription-management",
      // },
      {
        title: "Arbitrage",
        modules: "arbitrage",
        icon: "/images/DashboardIcon/arbitrageDark.png",
        // img: "images/dash2.png",
        isSub: true,
        items: [
          {
            title: "Direct",
            modules: "arbitrage",
            // icon: "/images/DashboardIcon/arbitrageDark.png",
            href: "/direct",
            isSub: false,
          },
          // {
          //   title: "Intra Exchange",
          //   href: "/intra",
          // isSub:true,
          // },
          {
            title: "Intra",
            modules: "arbitrage",
            // icon: "/images/DashboardIcon/arbitrageDark.png",
            href: "/intra",
            isSub: false,
          },
          {
            title: "Triangular",
            modules: "arbitrage",
            // icon: "/images/DashboardIcon/arbitrageDark.png",
            href: "/triangular",
            isSub: false,
          },

          // {
          //   title: "Loop",
          //   href: "/loop",
          // isSub:true,
          // },
          // {
          //   title: "Inter Exchange",
          //   href: "/market",
          // isSub:true,
          // },
        ],
      },
      // {
      //   title: "Profit Distribution Center",
      //   modules: "",
      //   icon: "/images/DashboardIcon/profitDark.png",
      //   href: "/profit-table",
      //   isSub: false,
      // },
      {
        title: "My Wallet",
        modules: "mywallet",
        icon: "/images/Dashboard/wallet.svg",
        href: "/my-wallet",
        isSub: false,
      },
      {
        title: "Transaction History",
        modules: "",
        icon: "/images/Dashboard/transaction.svg",
        href: "/transactions-history",
        isSub: false,
      },

      // {
      //   title: "Settings",
      //   modules: "",
      //   icon: RiSettingsLine,
      //   href: "/setting",
      // isSub:false,
      // },
      {
        title: "Settings",
        modules: "",
        icon: "/images/Dashboard/seeting.svg",
        href: "/google-authantication",
        isSub: false,
      },
      {
        title: "Auto Trade Settings",
        modules: "",
        icon: "/images/Dashboard/autotrade.svg",
        href: "/auto-trade",
        isSub: false,
      },

      // {
      //   title: "User Management",
      //   modules: "UserManagement",
      //   icon: "/images/DashboardIcon/userDark.png",
      //   href: "/user-management",
      //   isSub: false,
      // },
      // {
      //   title: "Invite",
      //   modules: "invite",
      //   icon: "/images/DashboardIcon/dashboardDark.png",
      //   href: "/invite",
      //   isSub: false,
      // },
      // {
      //   title: "Subscriptions",
      //   modules: "",
      //   icon: BsFillPersonCheckFill,
      //   href: "/subscription",
      // isSub:false,
      // },
      // {
      //   title: "Content Management",
      //   modules: "staticContentManagement",
      //   icon: "/images/DashboardIcon/staticDark.png",
      //   href: "/static-content-management",
      //   isSub: false,
      // },
      // {
      //   title: "FAQ Management",
      //   modules: "faqManagement",
      //   icon: "/images/DashboardIcon/faqDark.png",
      //   href: "/faqs",
      //   isSub: false,
      // },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
        isSub={item.isSub}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
        isSub={item.isSub}
      />
    );
  }
  return acc;
}
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 285,
    // background: theme.palette.background.header,
    background: "#2F49D1",
  },
  desktopDrawer: {
    top: "16px",
    width: "250px",
    height: "calc(100% - 35px)",
    margin: "10px",
    // background: theme.palette.background.sidebar,
    background: "#2F49D1",
    boxShadow: "none",
    borderRadius: "10px",
    marginTop: "-6px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",
    "&:hover": {
      color: "#F5C843",
    },
    "& svg": {
      color: "#F5C843",
      fontSize: "20px",
    },
  },
  btnBox: {
    position: "relative",
    left: "30%",
    bottom: "-250px",
  },
  logoutButton: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    position: "absolute",
    bottom: "-136px",
    left: "45px",
    background: "transparent",
    fontWeight: "400",
    fontSize: "13px",
  },
  sideMenuBox1: {
    "& .MuiCollapse-wrapperInner": {
      marginLeft: "45px",
    },
  },
  mainSiderBarBox: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "& .MuiIconButton-root": {
      background: "#8494E3",
      padding: "8px",
    },
    "& .profileBox": {
      position: "relative",
      margin: "10px 10px",
      display: "flex",
      padding: "10px",
      background: "#FEB53D",
      borderRadius: "10px",
      height: "41px",
      cursor: "pointer",
      alignItems: "center",
      "& img": {
        borderRadius: "50%",
      },
      "& h4": {
        fontWeight: 400,
        fontSize: "14px",
        color: "#262626",
      },
      "& p": {
        fontWeight: 400,
        color: "#57FFBC",
        marginBottom: "24px",
      },
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const themeSetting = useContext(SettingsContext);
  const currentTheme = themeSetting.theme;
  const auth = useContext(AuthContext);
  const location = useLocation();
  let permission = auth.userData.permissions;
  let connectedExchange = auth.userData?.connectedExchange
    ? auth.userData?.connectedExchange?.length
    : 0;

  const history = useHistory();
  const [isLogout, setIsLogout] = useState(false);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]); // eslint-disable-line
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const themeSeeting = useContext(SettingsContext);
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };
  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };
  const lightThemeImage = "/images/light_logo.png";
  const darkThemeImage = "/images/dashboard_logo.svg";
  const content = (
    <Box className={classes.mainSiderBarBox}>
      <Box
        style={{
          margin: "10px",
          marginBottom: "20px",
          paddingBottom: "11px",
          borderBottom: "1px solid #ffffff2b",
          cursor: "pointer",
        }}
      >
        <img
          src="/images/dashboard_logo.svg"
          alt="Dashboard"
          style={{ width: "auto", maxWidth: "179px" }}
        />
      </Box>

      <Box className="profileBox">
        <IconButton
          style={{
            position: "absolute",
            right: "0",
            background: "transparent",
          }}
          onClick={() => {
            history.push("/profile");
          }}
        >
          <MdOutlineNavigateNext style={{ color: "#262626" }} />
        </IconButton>
        <img
          height="40px"
          width="40px"
          src={
            auth?.userData?.profilePic
              ? auth?.userData?.profilePic
              : "images/editprofile.png"
          }
          alt={auth?.userData?.firstName}
        />

        <Typography variant="h4" color="primary" style={{ marginLeft: "16px" }}>
          {/* {auth?.userData?.firstName} {auth?.userData?.lastName} */}
          Shiwani Singh
        </Typography>
        {/* <Typography variant="body2" color="primary">
            Online
          </Typography> */}
      </Box>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box pt={2} pb={2}>
          <Box className="sideMenuBox1">
            {sections.map((section, i) => {
              let item;
              let item1 = section.items.map((data, i) => {
                // eslint-disable-next-line
                if (section?.items[i]?.modules == "") {
                  return section.items[i];
                }
                if (
                  section?.items[i]?.modules == "dashboard" && // eslint-disable-line
                  permission?.dashboard
                ) {
                  return section.items[i];
                } else if (
                  section?.items[i]?.modules == "exchange" && // eslint-disable-line
                  permission?.exchange
                ) {
                  // eslint-disable-next-line
                  if (connectedExchange == 0) {
                    return section.items[i];
                  } else {
                    return {
                      title: "Exchanges",
                      modules: "exchange",
                      icon: FaSquarespace,
                      // img: <img src="images/dash1.png"/>,
                      href: "/exchange",
                    };
                  }
                } else if (
                  section?.items[i]?.modules == "mywallet" && // eslint-disable-line
                  permission?.mywallet
                ) {
                  return section.items[i];
                } else if (
                  section?.items[i]?.modules == "subscription" && // eslint-disable-line
                  permission?.subscription
                ) {
                  return section.items[i];
                } else if (
                  section?.items[i]?.modules == "staticContentManagement" && // eslint-disable-line
                  permission?.staticContentManagement
                ) {
                  return section.items[i];
                } else if (
                  section?.items[i]?.modules == "arbitrage" && // eslint-disable-line
                  permission?.arbitrage
                ) {
                  return section.items[i];
                } else if (
                  section?.items[i]?.modules == "subscription" && // eslint-disable-line
                  permission?.subscription
                ) {
                  return section.items[i];
                } else {
                  return false;
                }
              });
              for (let k = 1; k < item1.length + 1; k++) {
                if (!item1[k]) {
                  item1.splice(k, 1);
                }
              }
              for (let l = 1; l < item1.length + 1; l++) {
                if (!item1[l]) {
                  item1.splice(l, 1);
                }
              }
              for (let m = 1; m < item1.length + 1; m++) {
                if (!item1[m]) {
                  item1.splice(m, 1);
                }
              }
              // eslint-disable-next-line
              if (auth.userData.userType == "SUBADMIN") {
                item = item1.filter((item) => item !== false); // eslint-disable-line
              } else {
                item = section.items; // eslint-disable-line
              }
              // ****************************************
              return (
                <List
                  key={`menu${i}`}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {section.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    img: section.img,
                    items: item,
                    pathname: location.pathname,
                  })}
                </List>
              );
            })}
          </Box>
        </Box>

        {/* <Button
          onClick={() => setIsLogout(true)}
          className={classes.logoutButton}
        >
          <ExitToAppIcon
            style={{
              marginRight: "16px",
            }}
          />
          &nbsp; Logout
        </Button>

        {isLogout && (
          <Dialog
            maxWidth="sm"
            fullWidth
            className={classes.dailogOpen}
            open={isLogout}
            // TransitionComponent={Transition}
            // keepMounted
            onClose={() => setIsLogout(false)}
          >
            <DialogContent>
              <Box className={classes.dialougTitle} align="center">
                <Typography variant="h6" color="primary">
                  Are you sure want to logout?
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions
              style={{
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Box mt={2}>
                <Button
                  className="modelbtn"
                  color="primary"
                  variant="contained"
                  style={{ padding: "8px 21px" }}
                  onClick={() => setIsLogout(false)}
                >
                  No
                </Button>
                &nbsp; &nbsp;
                <Button
                  className="modelbtnyes"
                  style={{
                    padding: "8px 21px",
                  }}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    window.localStorage.removeItem("token");
                    auth.userLogIn(false, null);
                    history.push("/");
                  }}
                >
                  Yes
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        )} */}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box p={2}>{content}</Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
